<template>
  <div
    v-if="
      $store.state.common.sUserType == 1 || $store.state.common.sUserType == 2
    "
  >
    <header
      style="
        width: 100%;
        background-color: #ffffff;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        border-bottom: solid 5px #000000;
        z-index: 9999;
      "
    >
      <div style="width: 100%; text-align: center; vertical-align: top">
        <div
          style="
            display: inline-block;
            width: 10%;
            padding: 5px;
            box-sizing: border-box;
            vertical-align: top;
          "
        >
          <span
            class="operate-button"
            @click="doGoHome"
            uk-icon="icon: home; ratio: 3"
          ></span>
        </div>
        <div
          style="
            display: inline-block;
            width: 80%;
            padding: 5px;
            box-sizing: border-box;
          "
        >
          <div
            style="
              display: inline-block;
              width: 100%;
              padding: 5px;
              box-sizing: border-box;
            "
          >
            <b>{{ $store.state.common.sComName }}</b>
          </div>
          <div
            style="
              display: inline-block;
              width: 100%;
              padding: 5px;
              box-sizing: border-box;
            "
          >
            <b>{{ $store.state.common.sUserName }}様</b>
          </div>
        </div>
        <div
          style="
            display: inline-block;
            width: 10%;
            padding: 5px;
            box-sizing: border-box;
            line-height: 4;
            vertical-align: top;
          "
        >
          <button
            type="button"
            class="operate-button"
            style="
              width: 100%;
              background-color: #ffffff;
              border-radius: 10px;
              border: solid 1px #696969;
              padding: 10px;
              box-sizing: border-box;
              color: #696969;
            "
            @click="doLogout"
          >
            ログアウト
          </button>
        </div>
      </div>
    </header>
  </div>

  <div v-else-if="$store.state.common.sUserType == 3">
    <header id="usrp">
      <div>
        <div class="usr_h_name">
          {{ $store.state.common.sComName }}
          {{ $store.state.common.sUserName }} 様
        </div>
        <div class="usr_h_bg">
          <!--div class="home"><span class="operate-button" @click="doGoHome" uk-icon="icon: home; ratio: 2"></span></div-->
          <div class="home">
            <img
              class="operate-button"
              @click="doGoHome"
              src="@/img/logo_small.png"
              alt="home"
            />
          </div>
          <div class="blnk"></div>
          <div class="pass">
            <span
              class="operate-button"
              @click="change_password"
              uk-icon="icon: cog; ratio: 2"
            ></span>
          </div>
          <div class="logout">
            <span
              class="operate-button"
              @click="doLogout"
              uk-icon="icon: sign-out; ratio: 2"
            ></span>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import constant from "@/constant.js";

export default {
  methods: {
    // ログアウト処理
    doLogout() {
      this.$store.commit("common/doLogout");
      this.$router.push("/login");
    },
    // ホームへ
    doGoHome() {
      console.log("ホームへ");
      switch (this.$store.state.common.sUserType) {
        // 管理者
        case constant.cUserType.intelligent:
          this.$router.push("/intelligent-home");
          break;
        // クライアント担当者
        case constant.cUserType.customer:
          this.$router.push("/customer-home");
          break;
        // 回答者
        case constant.cUserType.respondent:
          this.$router.push("/respondent-home");
          break;
      }
    },
    change_password() {
      if (this.$route.path == "/respondent-home") {
        this.$router.push("/respondent-changePW");
      } else {
        this.$router.replace("/respondent-changePW");
      }
    },
  },
};
</script>
