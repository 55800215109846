<template>
  <Header/>
  <!-- <div :style="$store.state.common.sUserType == 3 ? padding-mobile: padding" style="background-color:#f0fff0;"> -->
  <div :style="$store.state.common.sUserType == 3 ? paddingMobile: padding">
    <router-view/>
  </div>
</template>

<script>
  import constant from '@/constant.js'
  import Header from "./Header"

  export default {
    components: {
      Header
    },
    data() {
      return {
        'padding': {  // インテリジェント、クライアント
          'paddingTop': '100px'
        },
        'paddingMobile': {  // 回答者（モバイル想定）
          'paddingTop': '80px'
        },
      }
    },
    created() {
      if (!this.$store.state.common.sIsLogined) {
        this.$router.push('/login');
      }
      if (!this.$store.state.common.sUserType) {
        this.$router.push('/login');
      } else {
        switch (this.$store.state.common.sUserType) {
          case constant.cUserType.intelligent:
            this.$router.push('/intelligent-home');
            break;
          case constant.cUserType.customer:
            this.$router.push('/customer-home');
            break;
          case constant.cUserType.respondent:
            this.$router.push('/respondent-home');
            break;
        }
      }
      // インテリジェント担当者の場合はpaddingプロパティの調整をする（ヘッダーの下にメニューを表示する関係で）
      if (this.$store.state.common.sUserType == constant.cUserType.intelligent) {
        this.padding.paddingTop = '0px';
      }
    }
  }
</script>
